
import { RESLIST_CHANGE } from "../constants";

const initialState = {
    residentList: []
};

const reslistReducer = (state = initialState, action) => {
    switch(action.type) {
        case RESLIST_CHANGE:
            return {
                ...state,
                residentList: [action.payload]
            }
        default:
            return state;
    }
}

export default reslistReducer;