import './App.css';
import React, { useState, useEffect } from "react";
import  {GoogleSpreadsheet} from 'google-spreadsheet';
import Interactions from './components/Interactions';
import { Paper } from '@material-ui/core';
import { RESLIST_CHANGE } from './redux/constants'
import {useSelector, useDispatch} from 'react-redux';



const creds = require('./interactionsportal-47a4e70c8883.json');
const residentsDoc = new GoogleSpreadsheet('1xuUBPGKHQcPM0A2XJIMqziaTnTL4-tE1X78_ZtCVFQg');
const staffDoc = new GoogleSpreadsheet('1H5WhtMn1SmKIVaKoBIygljZaI8L6T2lt9FwVYs2nGMc');

const App = () => {

      
  const residentList = useSelector(state => state.residentList);
  const dispatch = useDispatch();
  const addToResList = resident => {
      dispatch({type: RESLIST_CHANGE, payload: resident})
  }

  const [id, setId] = useState("");
  const [pin, setPin] = useState("");
  const [staffRoster, setStaffRoster] = useState([]);
  const [loggedIn, setLoggedIn] = useState(false)
  const [raName, setRaName] = useState("");

  useEffect(() => {

    document.body.style.margin = 0;

    const loggedInOrNot = localStorage.getItem('loggedIn');
    const raNameStored = localStorage.getItem('raName');
    setRaName(raNameStored)
    if (loggedInOrNot === 'true') {
      setLoggedIn(true);  
    }

    getStaffRoster();
    console.log(residentList.residentList.length)
    getResidentNames();

    
  }, [])

  function handleSubmit(event) {
    event.preventDefault();
    for (let i = 0; i < staffRoster.length; i++){
      if (id === staffRoster[i][1] && pin === staffRoster[i][3]){
        setRaName(staffRoster[i][0]);
        localStorage.setItem('loggedIn', 'true');
        localStorage.setItem('raName', staffRoster[i][0])
        setLoggedIn(true)
      }
    }
  }

  const getResidentNames = async () => {
    try {
        await residentsDoc.useServiceAccountAuth(creds);
        await residentsDoc.loadInfo();

        const sheet = residentsDoc.sheetsByIndex[0];
        const result = await sheet.getRows();
        //console.log(result)
        formatResidentData(result);
    } catch (e) {
        console.error('Error: ', e);
    }
}

const getStaffRoster = async () => {
  try {
      await staffDoc.useServiceAccountAuth(creds);
      await staffDoc.loadInfo();

      const sheet = staffDoc.sheetsByIndex[0];
      const result = await sheet.getRows();
      formatStaffData(result);
  } catch (e) {
      console.error('Error: ', e);
  }
}

  const formatResidentData = (result) => {
    const rezzies = []
    for (let i = 0; i < result.length; i++){
      rezzies.push({room: result[i].Room, id: result[i].StudentID, name: result[i].ResidentName, age: result[i].age})
    }
    console.log(rezzies)
    addToResList(rezzies)
  }

  const formatStaffData = (result) => {
    for (let i = 0; i < result.length; i++){
      staffRoster.push([result[i].Name, result[i].ID, result[i].Email, result[i].Password]);
    }
  }


  if (loggedIn) {
    return (
    
    <Interactions raName={raName} setLoggedIn={setLoggedIn} setPin={setPin} setId={setId}/>
    )
  }
  else {
    return (
      <div className='page'>
      <div className='container' >
        <Paper className='paper' style={{borderRadius: 25, backgroundColor: '#ffffff'}}>
          <h3 className='loginHeader'>ResKit Interaction Portal Login</h3>
          <form onSubmit={handleSubmit} className='form'>
            <div className='inputDiv'>
              <label htmlFor="id" className='inputLabel'>Student ID </label>
              <input
                className='input'
                type="text"
                value={id}
                placeholder="Enter your Student ID"
                onChange={({ target }) => setId(target.value)}
              />
            </div>
            <div className='inputDiv'>
              <label htmlFor="pin" className='inputLabel'>4-digit Pin </label>
              <input
                className='input'
                type="text"
                value={pin}
                placeholder="Enter your 4-digit pin"
                onChange={({ target }) => setPin(target.value)}
              />
              <button type="submit" className='loginBtn'>Login</button>
            </div>
          </form>
      </Paper>
      </div>
      </div>
    )
  }
}

export default App;
