import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import Form from "react-bootstrap/Form";
import Button from 'react-bootstrap/Button';
import  {GoogleSpreadsheet} from 'google-spreadsheet';
import SearchBar from 'material-ui-search-bar';
import '../css/SubmitInteractions.css';
import Header from './Header';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ClearIcon from '@mui/icons-material/Clear';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from "@material-ui/core/IconButton";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { Alert } from 'react-bootstrap';
import { Paper } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { RESLIST_CHANGE } from '../redux/constants'

const creds = require('../interactionsportal-47a4e70c8883.json');
const residentsDoc = new GoogleSpreadsheet('1xuUBPGKHQcPM0A2XJIMqziaTnTL4-tE1X78_ZtCVFQg');
const doc = new GoogleSpreadsheet('13MnJJyuOHGdVhvUKUeI9XLKi4kPNNyi6JpW9pF3-SuU');

function createData(room, id, name, age) {
    return { room, id, name, age };
}

const useStyles = makeStyles((theme) => ({
    listRoot: {
      width: "100%",
      backgroundColor: "rgba(0,0,0,0.1)",
      marginTop: 12,
      "& .MuiListItem-root": {
        "&:hover": {
          color: "dodgerblue"
        },
        "&.MuiListItem-divider": {
          borderBottom: "2px solid rgba(0,0,0,0.1)"
        }
      }
    },
    subheader: {
      backgroundColor: "rgba(0,0,0,0.1)",
      fontSize: 24,
      "&.MuiListSubheader-inset": { marginBottom: 12 } //no space
    },
    listItemText: {
      "& .MuiListItemText-primary": {
        fontSize: "1.2rem"
      }
    },
  }));
  

const Interactions = ({raName, setLoggedIn, setPin, setId}) => {    
    const residentList = useSelector(state => state.residentList);
    const dispatch = useDispatch();
    const addToResList = resident => {
        dispatch({type: RESLIST_CHANGE, payload: resident})
    }
    //const names = [];

    useEffect(() => {
        getResidentNames();

        const current = new Date();
        const date2 = `${current.getMonth()+1}/${current.getDate()}/${current.getFullYear()}`;
        
        setCurrentWeek(date2);

    }, [])

    const getResidentNames = async () => {
        try {
            await residentsDoc.useServiceAccountAuth(creds);
            await residentsDoc.loadInfo();
    
            const sheet = residentsDoc.sheetsByIndex[0];
            const result = await sheet.getRows();
            //console.log(result)
            formatResidentData(result);
        } catch (e) {
            console.error('Error: ', e);
        }
    }

    const formatResidentData = (result) => {
        const rezzies = []
        for (let i = 0; i < result.length; i++){
          rezzies.push({room: result[i].Room, id: result[i].StudentID, name: result[i].ResidentName, age: result[i].age})
        }
        addToResList(rezzies)
        console.log(rezzies)
        setFetchedData(rezzies)
      }

    function logout() {
        localStorage.setItem('loggedIn', 'false');
        localStorage.setItem('raName', '');
        setPin("");
        setId("");
        setLoggedIn(false)
    }
    const [name, setName] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [showIncompleteError, setShowIncompleteError] = useState(false)
    const [selectedResidents, setSelectedResidents] = useState([]);
    const [date, setDate] = useState(getCurrentDate());
    const [week, setWeek] = useState("");
    const [details, setDetails] = useState("");
    const [fetchedData, setFetchedData] = useState([]);
    const [searchResults, setResults] = useState([]);
    const [searched, setSearched] = useState("");

    const onChangeWeek = text => {
        setWeek(text);
    };
    const onChangeDetails = text => {
        setDetails(text);
    };

    function setCurrentWeek(date) {
        if (date >  new Date("04/24/2022").getDate) {
            setWeek("04/24");
        } else if (date >  new Date("04/17/2022").getDate) {
            setWeek("04/17");
        } else if (date >  new Date("04/10/2022").getDate) {
            setWeek("04/10");
        } else if (date >  new Date("04/03/2022").getDate) {
            setWeek("04/03");
        } else if (date >  new Date("03/27/2022").getDate) {
            setWeek("03/27")
        } else if (date >  new Date("03/20/2022").getDate) {
            setWeek("03/20");
        } else if (date >  new Date("03/13/2022").getDate) {
            setWeek("03/13");
        } else if (date >  new Date("03/06/2022").getDate) {
            setWeek("03/06");
        } else if (date >  new Date("02/27/2022").getDate) {
            setWeek("02/27");
        } else if (date >  new Date("02/20/2022").getDate) {
            setWeek("02/20");
        } else if (date >  new Date("02/13/2022").getDate) {
            setWeek("02/13");
        } else if (date >  new Date("02/06/2022").getDate) {
            setWeek("02/06");
        } else if (date >  new Date("01/30/2022").getDate) {
            setWeek("01/30");
        } else if (date >  new Date("01/23/2022").getDate) {
            setWeek("01/23");
        } else if (date >  new Date("01/16/2022").getDate) {
            setWeek("01/16");
        } else {
            setWeek("01/09")
        }
    }

    const getWeekNumber = () => {
        switch (week) {
            case "01/09":
                return 1;
            case "01/16":
                return 2;
            case "01/23":
                return 3;
            case "01/30":
                return 4;
            case "02/06":
                return 5;
            case "02/13":
                return 6;
            case "02/20":
                return 7;
            case "02/27":
                return 8;
            case "03/06":
                return 9;
            case "03/13":
                return 10;
            case "03/20":
                return 11;
            case "03/27":
                return 12;
            case "04/03":
                return 13;
            case "04/10":
                return 14;
            case "04/17":
                return 15;
            case "04/24":
                return 16;
        }
    }
   
    const appendSpreadsheet = async (row) => {
        try {
            await doc.useServiceAccountAuth(creds);
            await doc.loadInfo();

            const sheet = doc.sheetsByIndex[0];
            const result = await sheet.addRow(row)
        } catch (e) {
            console.error('Error: ', e);
        }
    }


    function addInteraction(event) {
        event.preventDefault();
        if (selectedResidents.length > 0 && details !== ""){
            for (let x = 0; x < selectedResidents.length; x++) {
                const newRow = {
                    Facility: selectedResidents[x].room, 
                    Resident: selectedResidents[x].name,
                    StudentID: selectedResidents[x].id,
                    Week: getWeekNumber(),
                    Date: date.toString(),
                    Comments: details,
                    RA: raName
                }
                appendSpreadsheet(newRow);
            }
            setResults([]);
            requestSearch(searched)
            setSelectedResidents([]);
            setDate(getCurrentDate());
            setDetails("")

            const current = new Date();
            const date2 = `${current.getMonth()+1}/${current.getDate()}/${current.getFullYear()}`;
            setCurrentWeek(date2);
            setShowAlert(true);
            setShowIncompleteError(false)

        } else {
            setShowAlert(true)
            setShowIncompleteError(true)
        }
    };

    function getCurrentDate(){
        const newDate = new Date();
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        const formatedDate = `${year}-${month<10?`0${month}`:`${month}`}-${date}`
        return formatedDate;
    }

    const itemClicked = (item) => {
        selectedResidents.push(item);
        //console.log(selectedResidents)
        setName(item.name);
    }

    const deleteResident = (item) => {
        const newList = [];
        for (let y = 0; y < selectedResidents.length; y++) {
            if (selectedResidents[y].name !== item.name) {
                newList.push(selectedResidents[y]);
            }
        }
        setSelectedResidents(newList)
    }

    const requestSearch = (searchedVal) => {
        //console.log(fetchedData);
        if (searchedVal.substring(0, 1) === " ") {
            searchedVal = searchedVal.substring(1);
           // console.log(searchedVal);
        }
        setResults([])
        const filter = []
        
        for (let i = 0; i < fetchedData.length-2; i++) {
            if(typeof(fetchedData[i].name) !== 'undefined' && fetchedData[i].name !== null){
                if (fetchedData[i].name.toLowerCase().includes(searchedVal.toLowerCase())){
                    filter.push(fetchedData[i]);
                }
            }     
        }

        if (searchedVal === ""){
            setResults([]);
        }
        else {
            setResults(filter);
        }
      //console.log(searchResults)
    };  

    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };
      

    return (
      <div>
        <Header raName={raName} logout={logout}/>
        <div className='formContainer'>
          
          {showAlert ? (showIncompleteError ? 
            <Alert variant="danger" className='warningAlert' >
                <p className='alertText'>
                    Warning! Please fill out all fields before submitting.
                </p>
                <IconButton edge="end" className='clearSuccess' onClick={() => setShowAlert(false)}>
                    <ClearIcon className='clearIcon'/>
                </IconButton>
            </Alert> :
            <Alert variant="success" className='successAlert' >
                <p className='alertText'>
                    Success! Your interaction was submitted successfully.
                </p>
                <IconButton edge="end" className='clearSuccess' onClick={() => setShowAlert(false)}>
                    <ClearIcon className='clearIcon'/>
                </IconButton>
            </Alert>  
            )
            : <h3 className="header">Submit Interaction</h3>
        
            }
          <div className='innerContainer'> 
            <div className='leftFormContainer'>
            <SearchBar style={{width: '80%', marginTop: 5, marginBottom: 2}} value={searched} onRequestSearch={(searchVal) => requestSearch(searchVal)} onCancelSearch={() => cancelSearch()} placeholder='Search Residents'/>
                <Paper style={{height: 342, overflow: 'auto', width: '80%'}}>
                    <List
                        className={useStyles.listRoot}
                    >
                        {searchResults.map(f => (
                            <>
                                <ListItem  style={{cursor: 'pointer'}} key={f.id} >
                                    <ListItemText primary={f.name} />
                                    <ListItemSecondaryAction onClick={() => itemClicked(f)}>
                                        <IconButton edge="end" aria-label="comments">
                                            <AddCircleIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                            </>
                        ))}
                    </List>
                </Paper>
            </div>
            <div className="rightFormContainer">
                
                <p className='fieldTitle'>Resident Name(s)</p>
                <Paper style={{maxHeight: 130, minHeight: 130, overflow: 'auto', width: '95%'}}>
                    {selectedResidents.length > 0 ?
                    <List
                        className={useStyles.listRoot}
                    >
                        {selectedResidents.map(f => (
                          <>
                            <ListItem key={f.id}>
                                <ListItemText primary={f.name} />
                               
                                <ListItemSecondaryAction onClick={() => deleteResident(f)}>
                                    <IconButton edge="end" aria-label="comments">
                                        <ClearIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider/>
                          </>
                        ))}
                    </List> :
                    <p className='message'>Search and add residents to the left</p>
                }
                </Paper>
                <Form onSubmit={addInteraction}>
                    <p className='break'></p>
                    {/* Select Interaction Week */}
                    <Form.Group className='dates'>
                        <div className='dates2'>
                            <p className='dateTitle'>Week</p>
                            <Form.Control
                                as="select"
                                value={week}
                                style={{width: '100%', height: 30, fontSize: 14, borderWidth: 0, borderRadius: 4, cursor: 'pointer'}}
                                onChange={(e) => onChangeWeek(e.target.value)}
                            >       
                                <option value="01/09">Week 1 (Week of 01/09)</option>
                                <option value="01/16">Week 2 (Week of 01/16)</option>
                                <option value="01/23">Week 3 (Week of 01/23)</option>
                                <option value="01/30">Week 4 (Week of 01/30)</option>
                                <option value="02/06">Week 5 (Week of 02/06)</option>
                                <option value="02/13">Week 6 (Week of 02/13)</option>
                                <option value="02/20">Week 7 (Week of 02/20)</option>
                                <option value="02/27">Week 8 (Week of 02/27)</option>
                                <option value="03/06">Week 9 (Week of 03/06)</option>
                                <option value="03/13">Week 10 (Week of 03/13)</option>
                                <option value="03/20">Week 11 (Week of 03/20)</option>
                                <option value="03/27">Week 12 (Week of 03/27)</option>
                                <option value="04/03">Week 13 (Week of 04/03)</option>
                                <option value="04/10">Week 14 (Week of 04/10)</option>
                                <option value="04/17">Week 15 (Week of 04/17)</option>
                                <option value="04/24">Week 16 (Week of 04/24)</option>
                            </Form.Control>
                        </div>
                        <div className='dates2'>
                            <p className='dateTitle' style={{marginLeft: 12}}>Date</p>
                            <Form.Control
                                type='date'
                                value={date}
                                style={{width: "100%", height: 30, fontSize: 14, fontFamily: 'Lato'}}
                                className='dateSelector'
                                onChange={(e) => {setDate(e.target.value); console.log(e.target.value) }}
                            />
                        </div>
                    </Form.Group>
                    <p className='break'></p>

                    {/* Comments Entry */}
                    <p className='fieldTitle'>Interaction Details</p>
                    
                    <Form.Group className='details'>
                        <textarea
                        value={details}
                        rows={6}
                        placeholder='Enter details'
                        name="textValue"
                        className='details'
                        style={{width: '93%', paddingLeft: 8, paddingTop: 5}}
                        onChange={(e) => onChangeDetails(e.target.value)}
                        />
                    </Form.Group>
                    <p className='break'></p>
                    
                </Form>
            </div>
        </div>
        <Button block size="md" variant="secondary" type="submit" className='submitBtn' onClick={(e) => {setSearched(" "); addInteraction(e)}}>Add interaction</Button>

        </div>
      </div>
    )  

};

export default Interactions;
