import React from 'react';
import '../css/Header.css';


const Header = ({raName, logout}) => {
   
    return (
        <div className='headerPaper'>
                
                <h3 className='welcome'>Welcome, {raName}</h3>
                <h5 className='title'>Online Interactions Portal</h5>
                <button className='logoutBtn' onClick={logout} >Logout</button>
        </div>
    )

}

export default Header;